import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import logo from "../assets/logo.png";
import sajLogo from "../assets/sajLogo.png";
import Blocked from "../components/Blocked";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import React, { useEffect, useMemo, useState } from "react";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "80%",
    margin: "auto",
    display: "grid",
    gridTemplateRows: "1fr 3fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
        'topPart '
        'bottomPart'
      `,
    padding: "2rem 2rem 2rem 2rem ",
    gap: 10,
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  topPart: {
    boxSizing: "border-box",
    gridArea: "topPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 15,
  },
  logoImg: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  },
  spotRate: {
    width: "40%",
    backgroundColor: "#C19A39",
    border: "1px solid #948B5C",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "10px",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRowCol: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateValueGold: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color .5s ease",
  },
  spotRateValueSilver: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },

  bottomPart: {
    gridArea: "bottomPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 20,
    // boxSizing: "border-box",
  },
  commoditieTable: {
    height: "100%",
    width: "70%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
  },
  table: {
    gridArea: "table",
    gap: "15px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    backgroundColor: "#FFFFFF",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
    color: "#330411",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "83%",
    gap: 10,
    flexDirection: "column",
    color: "#FFFFFF",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "18%",
    maxHeight: "18%",
    flexGrow: 1,
    gridArea: "tableRow1",
    backgroundColor: "#DEBE6D",
    display: "flex",
    clipPath: " polygon(0 0, 100% 0, 97% 100%, 0 100%)",
  },
  bannerAndCurrency: {
    height: "100%",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "end",
  },
  carousel: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
  bannerImg: {
    width: "100%",
    height: "42vh",
    objectFit: "cover",
    borderRadius: "10px",
  },
  ratioAndNews: {
    padding: "0rem 2rem .5rem 2rem ",
    height: "20%",
    display: "flex",
    flexDirection: "column",
  },
  IconAndRatio: {
    height: "60%",
    width: "100%",
    display: "flex",
  },
  ratio: {
    height: "100%",
    width: "50%",
    gridArea: "stat",
    backgroundColor: "#002223",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingRight: "75px",
    clipPath: "polygon(0 0, 90% 0%, 100% 100%, 0% 100%)",
  },
  bullionStatsImg: {
    width: "18vw",
    marginLeft: "8em",
    height: "auto",
  },
  updates: {
    color: "#fff",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    color: "#FFFFFF",
    height: "40%",
  },
  updatesHeader: {
    background: "#002223",
    width: "15%",
    height: "82%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#C19A39",
    width: "100%",
    height: "80%",
    border: "1px solid #FFFFFF1A",
  },
  /////////////////////////////////////////
  currencyCommoditieTable: {
    height: "100%",
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
    borderRadius: "5px 5px 0 0",
  },
  currencyTable: {
    gridArea: "table",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: "5px 5px 0 0",
  },
  currencyTabeHeader: {
    backgroundColor: "#C19A39",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25%",
    color: "white",
    borderRadius: "5px 5px 0 0",
  },
  currencyTableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "75%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#FFFFFF",
    gap: 2,
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  currencyTableRowColumn: {
    // flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
    justifyContent: "center",
  },
  currencyTableRow: {
    flexBasis: 0,
    height: "25%",
    flexGrow: 1,
    backgroundColor: "#DEBE6D",
    display: "flex",
    color: "#000000",
  },
  currencyImage: {
    height: "20%",
  },
  /////////////////////////////////////////

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "0%",
    chgValue: "+0%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [goldNews, setGoldNews] = useState({});
  const [commodities, setCommodities] = useState([]);

  ////////////////////// Block //////////////////////////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ///////////////////// Subscription Expierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();

  ///////////////////// Subscription ExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now.day();
      const hour = now.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#2FB545";
  const sellersColor = "#D1172D";

  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div
      style={{ width: "100dvw", height: "100dvh" }}
      className={openSubscriptionExpierd && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.topPart}>
            <Box sx={{ height: "100%", width: "30%" }}>
              <img className={classes.logoImg} src={logo} alt="tvLogo" />
            </Box>

            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "20%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#002223",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#002223",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    &nbsp;
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GOLD
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -1.5,
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#F63544"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".9vw",
                        background: "#CE635B",
                        width: "3rem",
                        textAlign: "center",
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask) < Number(gold?.pre?.ask)
                          ? "#F63544"
                          : Number(gold?.cur?.ask) > Number(gold?.pre?.ask)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread?.goldAskSpread) +
                            Number(gold?.cur?.ask)
                          ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".9vw",
                        background: "#3DA1EA",
                        width: "3rem",
                        textAlign: "center",
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {!gold?.cur?.bid
                        ? "0.00"
                        : (
                            Number(spread?.goldHighSpread) +
                            Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                background: "#C19A39",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "20px",
                width: "30%",
                color: "white",
                paddingY: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.5vw",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                  mb: -1,
                }}
              >
                {formatTime()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.9vw",
                  letterSpacing: "2.2px",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                  mb: -1,
                }}
              >
                {formatday()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.8vw",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                }}
              >
                {formatDate()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.bottomPart}>
            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          maxHeight: commodities.length > 6 ? "25%" : "",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                            background: "#C19A39",
                            paddingLeft: { lg: ".5vw" },
                            clipPath: "polygon(0% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            background: "#CAA64C",
                            marginLeft: "-8px",
                            clipPath:
                              " polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "5.2vw", md: "5.2vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            background: "#D2B15D",
                            marginLeft: "-8px",
                            clipPath:
                              " polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "5.2vw", md: "5.2vw" },
                            }}
                          >
                            {!gold?.cur?.bid
                              ? "0.00"
                              : commodityCalculation(
                                  commodity.commodity_title === "Silver"
                                    ? silver?.cur?.bid
                                    : gold?.cur?.bid,
                                  commodity.commodity_title === "Silver"
                                    ? spread?.silverBidSpread
                                    : spread?.goldBidSpread,
                                  commodity?.buy_premium,
                                  3.674,
                                  commodity.purity,
                                  commodity.unit,
                                  commodity.unitLabel === "TTB"
                                    ? 116.64
                                    : commodity.unitLabel === "KG"
                                    ? 1000
                                    : commodity.unitLabel === "OZ"
                                    ? 31.1
                                    : commodity.unitLabel === "TOLA"
                                    ? 11.7
                                    : 1,
                                  commodity.buy_charge
                                )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            // marginLeft: "-8px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "5vw", md: "5vw" },
                            }}
                          >
                            {!gold?.cur?.bid
                              ? "0.00"
                              : commodityCalculation(
                                  commodity.commodity_title === "Silver"
                                    ? silver?.cur?.ask
                                    : gold?.cur?.ask,
                                  commodity.commodity_title === "Silver"
                                    ? spread.silverAskSpread
                                    : spread.goldAskSpread,
                                  commodity.premium,
                                  3.674,
                                  commodity.purity,
                                  commodity.unit,
                                  commodity.unitLabel === "TTB"
                                    ? 116.64
                                    : commodity.unitLabel === "KG"
                                    ? 1000
                                    : commodity.unitLabel === "OZ"
                                    ? 31.1
                                    : commodity.unitLabel === "TOLA"
                                    ? 11.7
                                    : 1,
                                  commodity.charges
                                )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.bannerAndCurrency}>
              <Box sx={{ height: "83%", width: "100%" }}>
                <Box className={classes.carousel}>
                  <Carousel
                    animation="fade" // Set the animation type to slide
                    navButtonsAlwaysVisible={false} // Show navigation buttons always
                    interval={10000}
                    indicatorContainerProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                    transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                    autoPlay
                    duration={2000}
                  >
                    {banners?.map((banner, i) => (
                      <img
                        className={classes.bannerImg}
                        src={banner.imageUrl}
                        alt="banner"
                      />
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.ratioAndNews}>
          <Box className={classes.IconAndRatio}>
            <Box
              sx={{
                height: "100%",
                width: "13%",
                background: "#132D32",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ objectFit: "contain", width: "90px", height: "90px" }}
                src={sajLogo}
                alt="logo"
              />
            </Box>
            <Box className={classes.ratio}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: "1vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2FB545"
                          : "#DE5B56",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", color: "#2FB545" }}>
                    {ratio?.Buyers}
                  </Typography>
                  {/* <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box> */}

                  <Typography sx={{ fontSize: "1vw", color: "#DE5B56" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
            <Box
              sx={{
                width: "37%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="https://www.bullionstats.com/">
                <img
                  className={classes.bullionStatsImg}
                  src={bullionStats}
                  alt="bullionStats"
                />
              </a>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.7vw",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                SAJ NEWS
              </Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <marquee
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {!adminData?.isNewsEnable &&
                  Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.6vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Gold Price News</span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: 500 }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
